import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestCaseActions = () => {
  return (
    <LayoutComponent>
      <h2>More Test Case actions</h2>
      <div>
        <h5>Clone Test Cases</h5>
        <p>
          Cloning a test case duplicates it with its test steps, but without all
          test results, comments, attachments, and defects of the original test
          case.
        </p>
        <ol>
          <li>
            Select the test cases you wish to clone and click the{" "}
            <strong>Clone</strong> button.
          </li>

          <li>
            The cloned test cases will appear in the list with "(CLONE)"
            appended to their titles. You can rename them by editing each test
            case.
          </li>
        </ol>
      </div>
      <br />
      <div>
        <h5>Archive Test Cases</h5>
        <p>
          To keep your test library organized, you can archive test cases that
          are not currently in use. Archiving will remove the selected test
          cases from all associated test suites and mark them as{" "}
          <strong>ARCHIVED</strong>. Archived test cases cannot be linked to
          test cycles or executed.
        </p>
        <ol>
          <li>
            Select the test cases you want to archive and click{" "}
            <strong>Archive</strong>.
          </li>
          <li>
            The selected test cases will be removed from all test suites and
            marked as <strong>ARCHIVED</strong>.
          </li>
          <li>
            Access archived test cases by clicking on{" "}
            <strong>Archived Test Cases</strong> above the test cases table.
          </li>
        </ol>

        <h5>Unarchive Test Cases</h5>
        <p>
          Unarchiving a test case will restore it to the active library. The
          status of the unarchived test case will be set to{" "}
          <strong>Draft</strong>, and it will not be assigned to any test suite
          or test cycle.
        </p>
        <ol>
          <li>
            Go to the <strong>Archived Test Cases</strong> section. Select the
            test cases you wish to restore and click <strong>Unarchive</strong>.
          </li>
          <li>
            The selected test cases will be moved back to the active library
            with their status set to <strong>Draft</strong>.
          </li>
          <li>
            Reassign the unarchived test cases to relevant test suites or test
            cycles as needed.
          </li>
        </ol>
      </div>

      <div>
        <h5>Filter Test Cases</h5>
        <p>
          Use filters to quickly find specific test cases based on various
          criteria such as status, priority, author, functional module, or
          technology layer.
        </p>
        <ol>
          <li>
            Click the <strong>Filter</strong> button in the Test Cases view.
          </li>

          <li>A modal will appear with the filter options.</li>
          <li>
            Select your desired filters by status, priority, author, module
            name, or technology layer.
          </li>

          <li>
            Click <strong>Apply</strong> to apply the filter.
          </li>
          <li>
            Applied filters will be shown below the search bar in the Test Cases
            view.
          </li>
        </ol>
      </div>
    </LayoutComponent>
  )
}

export default TestCaseActions
